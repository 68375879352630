import { Platform } from '@ionic/angular';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppConfig } from './app.config';
import { Logger, LogLevel } from './app-error-logger';
import { Personal, TourStatus, Fahrzeug, AdresseEntity, AppText } from '../model/swagger-model';
import { Storage } from '@ionic/storage';
import { IApp } from './iapp';
import { Config } from "@ionic/angular";
import { Dialogs } from '@ionic-native/dialogs/ngx';
import { Utils } from './utils';
import { NgZone } from '@angular/core';
import { LanguageListItem, NfcEvent, ScanNfcOptions } from '../model/model';
import { I18N } from './i18n';
import * as moment from 'moment';
import { SystemService } from '../system.service';
import { BarcodeAuthGuard } from 'src/app/shared/barcode.guard';
import { UiHelper } from './ui-helper';

const log = new Logger("App");

export class App implements IApp {
    static current: App = new App();

    private static isReady = false;

    static internetAvailable = new BehaviorSubject<boolean>(false);

    static delayCounter = 0;

    static versionCode = 22211;
    static version = '2.22.11';
    static packageName = 'de.recomobil.app';

    static loescheAlleDatenAktiv = false;

    static istImVordergrund = true;

    ngZone: NgZone;
    ionicConfig: Config
    platform: Platform;
    storage: Storage;
    dialogs: Dialogs;

    texte: AppText[] = [];

    backAction: () => void;

    loadingSpinner = new BehaviorSubject<boolean>(true);
    // overlayButtonsVisible = new BehaviorSubject<boolean>(false);
    // overlayButtons = new BehaviorSubject<any[]>([]);

    configRefreshed = new Subject<void>();
    onShowHomeNavigation = new Subject<void>();
    languageChanged = new Subject<void>();

    uvvBerechtigt = new BehaviorSubject<boolean>(false);

    tourStartenErforderlich = new BehaviorSubject<boolean>(false);
    transportauftraegeVerfuegbar = new BehaviorSubject<boolean>(false);
    kommissionierungVerfuegbar = new BehaviorSubject<boolean>(false);
    beladungVerfuegbar = new BehaviorSubject<boolean>(false);
    hofauftraegeVerfuegbar = new BehaviorSubject<boolean>(false);
    nachrichtenVerfuegbar = new BehaviorSubject<boolean>(false);
    wareneingangVerfuegbar = new BehaviorSubject<boolean>(false);
    glasAnalyseVerfuegbar = new BehaviorSubject<boolean>(false);

    NachrichtenMaxAlter = new BehaviorSubject<number>(30);
    NachrichtenSichtbarOhneAnmeldung = new BehaviorSubject<boolean>(false);
    NachrichtenSoundAbspielen = new BehaviorSubject<boolean>(true);
    NachrichtenSoundLautstaerke = new BehaviorSubject<number>(100);

    positionenAufzeichnen = new BehaviorSubject<boolean>(false);
    positionenSendenIntervall = new BehaviorSubject<number>(300);
    positionenAufzeichnenIntervall = new BehaviorSubject<number>(30);
    PositionenStationaryRadius = new BehaviorSubject<number>(30);
    PositionenDistanceFilter = new BehaviorSubject<number>(30);
    PositionenActivitiesInterval = new BehaviorSubject<number>(10);
    PositionenDesiredAccuracy = new BehaviorSubject<number>(0);
    PositionenBeiStillstandSenden = new BehaviorSubject<boolean>(false);
    PositionenDebugAktiv = new BehaviorSubject<boolean>(false);
    PositionenAufzeichnenAktivHalten = new BehaviorSubject<boolean>(true);

    alteAuftraegeLoeschenNachTagen = new BehaviorSubject<number>(7);
    fahrerAngemeldet = new BehaviorSubject<boolean>(false);
    tourGestartet = new BehaviorSubject<boolean>(false);
    abfahrtskontrolleFertig = new BehaviorSubject<boolean>(false);
    fahrer = new BehaviorSubject<Personal>(null);
    fahrzeug = new BehaviorSubject<Fahrzeug>(null);
    anhaenger = new BehaviorSubject<Fahrzeug>(null);
    anmeldungErforderlich = new BehaviorSubject<boolean>(null);
    nurKlingelnWennAngemeldet = new BehaviorSubject<boolean>(false);
    behaelterMitNfc = new BehaviorSubject<boolean>(true);
    behaelterMitBarcode = new BehaviorSubject<boolean>(true);
    behaelterAbstellorteVerwenden = new BehaviorSubject<boolean>(false);
    uvvVerfuegbar = new BehaviorSubject<boolean>(false);
    uvvSchnellpruefungVerfuegbar = new BehaviorSubject<boolean>(false);
    UvvSchnellpruefungZugriffe = new BehaviorSubject<number>(50);
    uvvNaechstePruefungTageAnzeigen = new BehaviorSubject<number>(30);
    behaelterNummerPruefung = new BehaviorSubject<string>('nein');
    dokumenteVerfuegbar = new BehaviorSubject<boolean>(false);
    dokumenteNurSichtbarWennAngemeldet = new BehaviorSubject<boolean>(false);
    auftragScannenNeuanlageErlaubt = new BehaviorSubject<boolean>(false);
    anmeldungStandardVerfahren = new BehaviorSubject<string>('nfc');
    auftragScannenVerfuegbar = new BehaviorSubject<boolean>(false);
    NachrichtenSendenVerfuegbar = new BehaviorSubject<boolean>(false);
    NachrichtenSymbolInTitelleiste = new BehaviorSubject<boolean>(false);
    UebersichtAnzeigen = new BehaviorSubject<boolean>(true);
    HomeKundenAnzeigen = new BehaviorSubject<boolean>(false);
    TransportauftragNeuanlageErlaubt = new BehaviorSubject<boolean>(false);
    HofauftraegeNeuanlageErlaubt = new BehaviorSubject<boolean>(false);
    AnhaengerVerfuegbar = new BehaviorSubject<boolean>(false);
    AuftragFooterAnzeigen = new BehaviorSubject<boolean>(true);
    TitelleisteAnzeigen = new BehaviorSubject<boolean>(true);
    BenutzereinstellungenVerfuegbar = new BehaviorSubject<boolean>(true);
    SmartwatchVerfuegbar = new BehaviorSubject<boolean>(true);
    TourStartMehrereTourenMoeglich = new BehaviorSubject<boolean>(true);
    NativeDialogeBevorzugen = false;

    PositionenExaktMeter = 15;
    AbfahrtskontrolleMinDauer = 0;
    AnkunftskontrolleMinDauer = 0;
    druckerSuchenTimeout = 10 * 1000;
    druckenTimeout = 30 * 1000;
    aufloesungFotos = 1000;
    qualitaetFotos = 85;
    signaturMinWidth = 2.5;
    signaturMaxWidth = 8;
    GpsPositionTimeout = 2500;

    auftragZeilenhoehe: 'Standard' | 'Dynamisch' | 'Fix' = 'Standard';
    auftragZeilenhoeheMin = 80;
    auftragInfoTextMaxLaenge = 80;

    hofauftragZeile1Anzeigen = true;
    hofauftragZeile2Anzeigen = true;
    hofauftragZeile3Anzeigen = true;
    hofauftragAbholdatumAnzeigen = true;

    transportauftragZeile1Anzeigen = true;
    transportauftragZeile2Anzeigen = true;
    transportauftragZeile3Anzeigen = true;
    transportauftragAbholdatumAnzeigen = true;

    TourEndeWiegenBarcodesButtonsAnzeigen = new BehaviorSubject<boolean>(false);
    TransportauftragReihenfolgeAnzeigen = new BehaviorSubject<boolean>(false);
    HomeNavigationVerfuegbar = new BehaviorSubject<boolean>(false);

    druckerVerfuegbar = new BehaviorSubject<boolean>(false);
    waageVerfuegbar = new BehaviorSubject<boolean>(false);
    GalaxyWatchVerfuegbar = new BehaviorSubject<boolean>(false);
    ProGloveVerfuegbar = new BehaviorSubject<boolean>(false);

    spracheAendernErlaubt = false;

    NfcFormat: 'standard' | 'reverse' = 'standard';
    NfcPayloadVerwenden = true;

    /** Die aktuell gestartete Tour */
    aktuelleTour = new BehaviorSubject<TourStatus>(null);

    /** Die per 'Tour laden' geladene Tour. Das ist nicht die gestartete tour */
    aktuellGeladeneTour = new BehaviorSubject<TourStatus>(null);

    geladen = new BehaviorSubject<boolean>(false);

    klingelnBlockiert = new BehaviorSubject<boolean>(false);

    dateFormat = 'dd.MM.yyyy';
    dateTimeFormat = 'dd.MM.yyyy HH:mm';

    nfcRegistrations = new Map<string, number>();

    nfcAlert: HTMLIonAlertElement = null;

    constructor() {
        Logger.app = this;
    }

    static loadingDelayed(value: boolean, delay = 500) {
        const counter = ++App.delayCounter;

        if (value) {
            setTimeout(() => {
                if (counter === App.delayCounter) {
                    App.current.loadingSpinner.next(value);
                }
            }, delay);
        } else {
            App.current.loadingSpinner.next(value);
        }
    }

    static loading(value: boolean) {
        App.current.ngZone.run(() => {
            App.delayCounter++;
            App.current.loadingSpinner.next(value);
        });
    }

    static isLoading(): boolean {
        return App.current.loadingSpinner.getValue();
    }

    static delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    static isCordovaAvailable(): boolean {
        return App.current.platform.is('cordova');
    }

    static isInternetAvailable(): boolean {
        // Wenn Cordova nicht verfügbar ist, sind wir in einer Testumgebung ohne Internet
        return App.internetAvailable.getValue() || !App.isCordovaAvailable();
    }

    static async ready(): Promise<void> {
        if (!App.isReady) {
            const promise = new Promise(async resolve => {
                let istResolved = false;

                // Maximal 15 Sekunden warten. Wegen fehlenden Plusin wird platform.ready() nicht immer ausgelöst (im Browser)
                setTimeout(() => {
                    if (!istResolved) {
                        log.warn('platform.ready nicht innerhalb vo 15 Sekunden erreicht')
                        istResolved = true;
                        resolve(null);
                    }
                }, 15 * 1000);

                if (App.current.platform) {
                    await App.current.platform.ready();
                    log.debug('platform ready');
                } else {
                    log.error('App.current.platform darf nicht null sein');
                }

                if (App.current.storage) {
                    await App.current.storage.ready();
                    log.debug('storage ready');
                } else {
                    log.error('App.current.storage darf nicht null sein');
                }

                if (!istResolved) {
                    istResolved = true;
                    resolve(null);
                }
            });

            await promise;

            App.isReady = true;
        }
    }

    getVersionNumber() {
        return App.version;
    }

    getPersonalKey() {
        const fahrer = App.current.fahrer.getValue();

        if (fahrer) {
            return fahrer.Key;
        }

        return null;
    }

    getPersonalnummer() {
        let personalnummer = null;
        const fahrer = App.current.fahrer.getValue();

        if (fahrer) {
            personalnummer = fahrer.personalnummer;
        }

        return personalnummer;
    }

    getPersonalName() {
        const fahrer = App.current.fahrer.getValue();

        if (fahrer) {
            return fahrer.vorname + ' ' + fahrer.nachname;
        }

        return '';
    }

    getFahrzeugKey() {
        const fahrzeug = App.current.fahrzeug.getValue();

        if (fahrzeug) {
            return fahrzeug.key;
        }

        return null;
    }

    getFahrzeugKennzeichen() {
        const fz = this.fahrzeug.getValue();

        if (fz) {
            return fz.kennzeichen;
        }

        return '';
    }

    getAnhaengerKey() {
        const anhaenger = App.current.anhaenger.getValue();

        if (anhaenger) {
            return anhaenger.key;
        }

        return null;
    }

    getAnhaengerKennzeichen() {
        const anhaenger = this.anhaenger.getValue();

        if (anhaenger) {
            return anhaenger.kennzeichen;
        }

        return '';
    }

    init() {
        // this.platform.backButton.subscribe(() => {
        //     if (typeof (this.backAction) === 'function') {
        //         this.log.debug('backButton pressed. calling action...');
        //         this.backAction();
        //     } else {
        //         this.log.debug('backButton pressed. no action found');
        //     }
        // });
    }

    refresh() {
        log.debug('refresh');

        const appConfig = AppConfig.current;

        if (appConfig.einstellungen) {
            if (appConfig.einstellungen.LogLevel == 'Debug' || !App.isCordovaAvailable()) {
                console.log('Set LogLevel to DEBUG');
                Logger.globalLogLevel = LogLevel.DEBUG;
            } else if (appConfig.einstellungen.LogLevel == 'Info') {
                console.log('Set LogLevel to INFO');
                Logger.globalLogLevel = LogLevel.INFO;
            } else if (appConfig.einstellungen.LogLevel == 'Warn') {
                console.log('Set LogLevel to WARN');
                Logger.globalLogLevel = LogLevel.WARN;
            } else if (appConfig.einstellungen.LogLevel == 'Error') {
                console.log('Set LogLevel to ERROR');
                Logger.globalLogLevel = LogLevel.ERROR;
            } else if (appConfig.einstellungen.LogLevel == null || typeof (appConfig.einstellungen.LogLevel) === 'undefined') {
                console.log('Set LogLevel to DEBUG (initial)');
                Logger.globalLogLevel = LogLevel.DEBUG;
            } else {
                console.log('Set LogLevel to INFO (default)');
                Logger.globalLogLevel = LogLevel.INFO;
            }

            this.tourStartenErforderlich.next(appConfig.einstellungen.TourStartenErforderlich === 'ja');
            this.transportauftraegeVerfuegbar.next(appConfig.einstellungen.Transportauftraege === 'ja');
            this.kommissionierungVerfuegbar.next(appConfig.einstellungen.KommissionierungVerfuegbar === 'ja');
            this.beladungVerfuegbar.next(appConfig.einstellungen.BeladungVerfuegbar === 'ja');
            this.hofauftraegeVerfuegbar.next(appConfig.einstellungen.Hofauftraege === 'ja');
            this.nachrichtenVerfuegbar.next(appConfig.einstellungen.Nachrichten === 'ja');
            this.anmeldungErforderlich.next(appConfig.einstellungen.AnmeldungErforderlich === 'ja');
            this.nurKlingelnWennAngemeldet.next(appConfig.einstellungen.NurKlingelnWennAngemeldet === 'ja');
            this.behaelterMitNfc.next(appConfig.einstellungen.BehaelterMitNfc === 'ja');
            this.behaelterMitBarcode.next(appConfig.einstellungen.BehaelterMitBarcode === 'ja');
            this.uvvVerfuegbar.next(appConfig.einstellungen.UvvVerfuegbar === 'ja');
            this.uvvSchnellpruefungVerfuegbar.next(appConfig.einstellungen.UvvSchnellpruefungVerfuegbar === 'ja');
            this.dokumenteVerfuegbar.next(appConfig.einstellungen.DokumenteVerfuegbar === 'ja');
            this.dokumenteNurSichtbarWennAngemeldet.next(appConfig.einstellungen.DokumenteNurSichtbarWennAngemeldet === 'ja');
            this.auftragScannenVerfuegbar.next(appConfig.einstellungen.AuftragScannenVerfuegbar === 'ja');
            this.auftragScannenNeuanlageErlaubt.next(appConfig.einstellungen.AuftragScannenNeuanlageErlaubt === 'ja');
            this.behaelterAbstellorteVerwenden.next(appConfig.einstellungen.BehaelterAbstellorteVerwenden === 'ja');
            this.TransportauftragNeuanlageErlaubt.next(appConfig.einstellungen.TransportauftragNeuanlageErlaubt === 'ja');
            this.HofauftraegeNeuanlageErlaubt.next(appConfig.einstellungen.HofauftraegeNeuanlageErlaubt === 'ja');
            this.wareneingangVerfuegbar.next(appConfig.einstellungen.WareneingangVerfuegbar === 'ja');
            this.glasAnalyseVerfuegbar.next(appConfig.einstellungen.GlasAnalyseVerfuegbar === 'ja');

            this.NfcPayloadVerwenden = AppConfig.current.einstellungen.NfcPayloadVerwenden !== 'nein';
            this.NfcFormat = AppConfig.current.einstellungen.NfcFormat;

            this.druckerVerfuegbar.next(appConfig.einstellungen.DruckerVerfuegbar === 'ja');
            this.waageVerfuegbar.next(appConfig.einstellungen.WaageVerfuegbar === 'ja');
            this.GalaxyWatchVerfuegbar.next(appConfig.einstellungen.GalaxyWatchVerfuegbar === 'ja');
            this.SmartwatchVerfuegbar.next(appConfig.einstellungen.GalaxyWatchVerfuegbar === 'ja');
            this.ProGloveVerfuegbar.next(appConfig.einstellungen.ProgloveVerfuegbar === 'ja');

            if (appConfig.einstellungen.AufloesungFotos) {
                this.aufloesungFotos = parseInt(appConfig.einstellungen.AufloesungFotos, 10);

                if (this.aufloesungFotos >= 9999) {
                    this.aufloesungFotos = undefined;
                }
            }

            if (appConfig.einstellungen.QualitaetFotos) {
                this.qualitaetFotos = parseInt(appConfig.einstellungen.QualitaetFotos, 10);
            }

            if (appConfig.einstellungen.PositionenSendenIntervall) {
                this.positionenSendenIntervall.next(parseInt(appConfig.einstellungen.PositionenSendenIntervall, 10));
            }

            if (appConfig.einstellungen.PositionenAufzeichnenIntervall) {
                this.positionenAufzeichnenIntervall.next(parseInt(appConfig.einstellungen.PositionenAufzeichnenIntervall, 10));
            }

            if (appConfig.einstellungen.PositionenStationaryRadius) {
                this.PositionenStationaryRadius.next(parseInt(appConfig.einstellungen.PositionenStationaryRadius, 10));
            }

            if (appConfig.einstellungen.PositionenDistanceFilter) {
                this.PositionenDistanceFilter.next(parseInt(appConfig.einstellungen.PositionenDistanceFilter, 10));
            }

            if (appConfig.einstellungen.PositionenActivitiesInterval) {
                this.PositionenActivitiesInterval.next(parseInt(appConfig.einstellungen.PositionenActivitiesInterval, 10));
            }

            if (appConfig.einstellungen.PositionenDesiredAccuracy) {
                this.PositionenDesiredAccuracy.next(parseInt(appConfig.einstellungen.PositionenDesiredAccuracy, 10));
            }

            this.PositionenAufzeichnenAktivHalten.next(appConfig.einstellungen.PositionenAufzeichnenAktivHalten === 'ja');
            this.PositionenBeiStillstandSenden.next(appConfig.einstellungen.PositionenBeiStillstandSenden === 'ja');
            this.PositionenDebugAktiv.next(appConfig.einstellungen.PositionenDebugAktiv === 'ja');
            this.positionenAufzeichnen.next(appConfig.einstellungen.PositionenAufzeichnen === 'ja');

            if (appConfig.einstellungen.GpsPositionTimeout) {
                this.GpsPositionTimeout = parseInt(appConfig.einstellungen.GpsPositionTimeout, 10);
            }

            if (appConfig.einstellungen.AlteAuftraegeLoeschenNachTagen) {
                this.alteAuftraegeLoeschenNachTagen.next(parseFloat(appConfig.einstellungen.AlteAuftraegeLoeschenNachTagen));
            }

            if (appConfig.einstellungen.UvvNaechstePruefungTageAnzeigen) {
                this.uvvNaechstePruefungTageAnzeigen.next(parseInt(appConfig.einstellungen.UvvNaechstePruefungTageAnzeigen, 10));
            }

            if (!appConfig.einstellungen.BehaelterNummerPruefung) {
                appConfig.einstellungen.BehaelterNummerPruefung = 'nein';
            }

            this.behaelterNummerPruefung.next(appConfig.einstellungen.BehaelterNummerPruefung.toLowerCase());

            if (appConfig.einstellungen.AnmeldungStandardVerfahren) {
                this.anmeldungStandardVerfahren.next(appConfig.einstellungen.AnmeldungStandardVerfahren.toLowerCase());
            } else {
                this.anmeldungStandardVerfahren.next('nfc');
            }

            if (appConfig.einstellungen.NachrichtenMaxAlter) {
                this.NachrichtenMaxAlter.next(parseInt(appConfig.einstellungen.NachrichtenMaxAlter, 10));
            }

            this.NachrichtenSichtbarOhneAnmeldung.next(appConfig.einstellungen.NachrichtenSichtbarOhneAnmeldung === 'ja');
            this.NachrichtenSoundAbspielen.next(appConfig.einstellungen.NachrichtenSoundAbspielen === 'ja');

            if (appConfig.einstellungen.NachrichtenSoundLautstaerke) {
                this.NachrichtenSoundLautstaerke.next(parseInt(appConfig.einstellungen.NachrichtenSoundLautstaerke, 10));
            }

            if (appConfig.einstellungen.SignaturMinWidth) {
                this.signaturMinWidth = parseFloat(appConfig.einstellungen.SignaturMinWidth);
            }

            if (appConfig.einstellungen.SignaturMaxWidth) {
                this.signaturMaxWidth = parseFloat(appConfig.einstellungen.SignaturMaxWidth);
            }

            if (appConfig.einstellungen.AuftragInfoTextMaxLaenge) {
                this.auftragInfoTextMaxLaenge = Utils.parseInt(appConfig.einstellungen.AuftragInfoTextMaxLaenge);
            } else {
                this.auftragInfoTextMaxLaenge = 80;
            }

            if (appConfig.einstellungen.AuftragZeilenhoehe) {
                this.auftragZeilenhoehe = appConfig.einstellungen.AuftragZeilenhoehe;
            } else {
                this.auftragZeilenhoehe = 'Standard';
            }

            if (appConfig.einstellungen.AuftragZeilenhoeheMin) {
                this.auftragZeilenhoeheMin = Utils.parseInt(appConfig.einstellungen.AuftragZeilenhoeheMin);
            } else {
                this.auftragZeilenhoeheMin = 80;
            }

            this.TourEndeWiegenBarcodesButtonsAnzeigen.next(appConfig.einstellungen.TourEndeWiegenBarcodesButtonsAnzeigen === 'ja');
            this.NachrichtenSendenVerfuegbar.next(appConfig.einstellungen.NachrichtenSendenVerfuegbar === 'ja');
            this.TransportauftragReihenfolgeAnzeigen.next(appConfig.einstellungen.TransportauftragReihenfolgeAnzeigen === 'ja');
            this.UebersichtAnzeigen.next(appConfig.einstellungen.UebersichtAnzeigen === 'ja');
            this.HomeNavigationVerfuegbar.next(appConfig.einstellungen.HomeNavigationVerfuegbar === 'ja');
            this.AnhaengerVerfuegbar.next(appConfig.einstellungen.AnhaengerVerfuegbar === 'ja');
            this.AuftragFooterAnzeigen.next(appConfig.einstellungen.AuftragFooterAnzeigen === 'ja');
            this.NachrichtenSymbolInTitelleiste.next(this.nachrichtenVerfuegbar.getValue() && appConfig.einstellungen.NachrichtenSymbolInTitelleiste === 'ja');
            this.TitelleisteAnzeigen.next(appConfig.einstellungen.TitelleisteAnzeigen !== 'nein');
            this.BenutzereinstellungenVerfuegbar.next(appConfig.einstellungen.BenutzereinstellungenVerfuegbar === 'ja');
            this.TourStartMehrereTourenMoeglich.next(appConfig.einstellungen.TourStartMehrereTourenMoeglich === 'ja');
            this.HomeKundenAnzeigen.next(appConfig.einstellungen.LiveDatenVerfuegbar === 'ja' && appConfig.einstellungen.HomeKundenAnzeigen === 'ja');

            if (appConfig.einstellungen.AbfahrtskontrolleMinDauer) {
                this.AbfahrtskontrolleMinDauer = parseInt(appConfig.einstellungen.AbfahrtskontrolleMinDauer, 10);
            } else {
                this.AbfahrtskontrolleMinDauer = 0;
            }

            if (appConfig.einstellungen.AnkunftskontrolleMinDauer) {
                this.AnkunftskontrolleMinDauer = parseInt(appConfig.einstellungen.AnkunftskontrolleMinDauer, 10);
            } else {
                this.AnkunftskontrolleMinDauer = 0;
            }

            if (appConfig.einstellungen.PositionenExaktMeter) {
                this.PositionenExaktMeter = parseInt(appConfig.einstellungen.PositionenExaktMeter, 10);
            } else {
                this.PositionenExaktMeter = 15;
            }

            this.NativeDialogeBevorzugen = appConfig.einstellungen.NativeDialogeBevorzugen === 'ja';
            this.spracheAendernErlaubt = appConfig.einstellungen.SpracheWechseln === 'ja';

            this.hofauftragZeile1Anzeigen = appConfig.einstellungen.HofauftragZeile1Anzeigen !== 'nein';
            this.hofauftragZeile2Anzeigen = appConfig.einstellungen.HofauftragZeile2Anzeigen !== 'nein';
            this.hofauftragZeile3Anzeigen = appConfig.einstellungen.HofauftragZeile3Anzeigen !== 'nein';
            this.hofauftragAbholdatumAnzeigen = appConfig.einstellungen.HofauftragAbholdatumAnzeigen !== 'nein';

            this.transportauftragZeile1Anzeigen = appConfig.einstellungen.TransportauftragZeile1Anzeigen !== 'nein';
            this.transportauftragZeile2Anzeigen = appConfig.einstellungen.TransportauftragZeile2Anzeigen !== 'nein';
            this.transportauftragZeile3Anzeigen = appConfig.einstellungen.TransportauftragZeile3Anzeigen !== 'nein';
            this.transportauftragAbholdatumAnzeigen = appConfig.einstellungen.TransportauftragAbholdatumAnzeigen !== 'nein';

            localStorage.setItem('Animationen', appConfig.einstellungen.Animationen);

            try {
                // const isAnimated = appConfig.einstellungen.Animationen == 'ja';

                // log.debug('isAnimated: ' + isAnimated);

                // this.ionicConfig.set("animated", isAnimated);
            } catch (err) {
                log.warn('Fehler beim Setzen von "animated": ' + Utils.getErrorMessage(err));
            }

            this.refreshDrucker();
            this.refreshWaagen();
        }

        this.configRefreshed.next();
    }

    refreshWaagen() {
        const appConfig = AppConfig.current;

        if (!appConfig.gekoppelteWaagen) {
            appConfig.gekoppelteWaagen = [];
        }

        appConfig.gekoppelteWaagen.forEach(p => p.istVorkonfiguriert = false);

        if (appConfig.einstellungen.WaageMacAdresse) {
            const zeilen = appConfig.einstellungen.WaageMacAdresse.split("\n");

            for (const zeile of zeilen) {
                if (!zeile || !zeile.trim()) {
                    continue;
                }

                const parts = zeile.split(';');

                const macAdresse = parts[0];
                const name = parts.length >= 1 ? parts[1] : '';
                const typ = parts.length >= 2 ? parts[2] : appConfig.einstellungen.WaageTyp;

                const waage = appConfig.gekoppelteWaagen.find(p => p.macAdresse && p.macAdresse.toLowerCase() == macAdresse.toLowerCase());

                if (waage) {
                    waage.istVorkonfiguriert = true;

                    if (name) {
                        waage.name = name;
                    }

                    if (typ) {
                        waage.typ = typ;
                    }
                } else if (!waage) {
                    appConfig.gekoppelteWaagen.push({
                        istVorkonfiguriert: true,
                        aktiv: true,
                        macAdresse,
                        typ,
                        zuletztVerwendet: '',
                        name
                    });
                }
            }
        }
    }

    refreshDrucker() {
        const appConfig = AppConfig.current;

        if (!appConfig.gekoppelteDrucker) {
            appConfig.gekoppelteDrucker = [];
        }

        appConfig.gekoppelteDrucker.forEach(p => p.istVorkonfiguriert = false);

        if (appConfig.einstellungen.DruckerMacAdressen) {
            const zeilen = appConfig.einstellungen.DruckerMacAdressen.split("\n");

            for (const zeile of zeilen) {
                if (!zeile || !zeile.trim()) {
                    continue;
                }

                const parts = zeile.split(';');

                const macAdresse = parts[0];
                const name = parts.length >= 1 ? parts[1] : '';
                const typ = parts.length >= 2 ? parts[2] : appConfig.einstellungen.DruckerTyp;

                const drucker = appConfig.gekoppelteDrucker.find(p => p.macAdresse.toLowerCase() == macAdresse.toLowerCase());

                if (drucker) {
                    drucker.istVorkonfiguriert = true;

                    if (name) {
                        drucker.name = name;
                    }

                    if (typ) {
                        drucker.typ = typ;
                    }
                } else if (!drucker) {
                    appConfig.gekoppelteDrucker.push({
                        istVorkonfiguriert: true,
                        aktiv: true,
                        macAdresse,
                        typ,
                        zuletztVerwendet: '',
                        name
                    });
                }
            }
        }

        if (appConfig.einstellungen.DruckerSuchenTimeout) {
            this.druckerSuchenTimeout = parseInt(appConfig.einstellungen.DruckerSuchenTimeout, 10);

            if (this.druckerSuchenTimeout < 1000) {
                this.druckerSuchenTimeout = 1000;
            }
        }

        if (appConfig.einstellungen.DruckenTimeout) {
            const value = parseInt(appConfig.einstellungen.DruckenTimeout, 10);

            if (value > 1000) {
                this.druckenTimeout = value;
            }
        }
    }

    closeKeyboard(event) {
        if (event.target) {
            const input = event.target as HTMLInputElement;

            try {
                input.blur();
            } catch (err) {
                log.warn(err);
            }
        }
    }

    static closeAnyKayboard() {
        try {
            const e = document.activeElement as HTMLInputElement;

            if (e && typeof (e.blur) === 'function') {
                e.blur();
            }
        } catch (err) {
            log.info('Fehler closeAnyKayboard: ' + Utils.getErrorMessage(err));
        }
    }

    getLanguageItems(): LanguageListItem[] {
        const list: LanguageListItem[] = [
            { language: 'DE', text: 'Deutsch', icon: 'de.png' },
            { language: 'EN', text: 'English', icon: 'us.png' },
            // { language: 'IT', text: 'Italiano', icon: 'it.png' },
            { language: 'PL', text: 'Polska', icon: 'pl.png' },
        ];

        return list;
    }

    setLanguage(language: string) {
        language = Utils.trimToEmpty(language).toUpperCase();

        I18N.translateService.use(language.toLowerCase());

        AppConfig.current.language = language;

        moment.locale(language);

        this.languageChanged.next();
    }

    async scanNFC(key: string, prio: number, func: (event: NfcEvent) => void) {
        const systemService = SystemService.instance;
        const oldRegId = this.nfcRegistrations.get(key);

        if (oldRegId) {
            systemService.unregisterNfcReceiver(oldRegId);
        }

        const registrationId = systemService.registerNfcReceiver(prio, (nfcEvent) => {
            func(nfcEvent);
            
            this.nfcAlert?.dismiss();
        });

        this.nfcRegistrations.set(key, registrationId);

        BarcodeAuthGuard.barcodeScanAktiv = true;

        if (this.nfcAlert) {
            this.nfcAlert.dismiss();
            this.nfcAlert = null;
        }

        this.nfcAlert = await UiHelper.alertCtrl.create({
            mode: 'ios',
            message: I18N.instant('NFCErkennungLaeuft'),
            buttons: [{
                text: I18N.instant('Abbrechen'),
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                }
            },
            ]

        });

        if (!App.isCordovaAvailable()) {
            setTimeout(() => {
                if (this.nfcAlert) {
                    this.nfcAlert.dismiss();

                    func({
                        id: [],
                        payload: '',
                        payloadList: [],
                        seriennummer: key
                    })
                }
            }, 2000);
        }

        await this.nfcAlert.present();

        await this.nfcAlert.onDidDismiss();

        this.nfcAlert = null;

        systemService.unregisterNfcReceiver(registrationId);

        this.nfcRegistrations.delete(key);

        // if (this.auftragdetails) {
        //     this.auftragdetails.starteAuftragWennErforderlich('scanEigenschaftNfc');
        // }

        setTimeout(() => BarcodeAuthGuard.barcodeScanAktiv = false, 500);
    }

    async scanNfcBackground(key: string, func: (event: NfcEvent) => void, options: ScanNfcOptions = null) {
        const defaultOptions: ScanNfcOptions = {
            prio: 100,
            simulateSeriennummer: '',
            simulateTimeout: 2000
        }

        options = Object.assign(defaultOptions, options);

        const systemService = SystemService.instance;
        const oldRegId = this.nfcRegistrations.get(key);

        if (oldRegId) {
            systemService.unregisterNfcReceiver(oldRegId);
        }

        const registrationId = systemService.registerNfcReceiver(options.prio, (nfcEvent) => {
            func(nfcEvent);
        });

        this.nfcRegistrations.set(key, registrationId);

        BarcodeAuthGuard.barcodeScanAktiv = true;

        if (!App.isCordovaAvailable() && options.simulateSeriennummer) {
            setTimeout(() => {
                const regId = this.nfcRegistrations.get(key);

                if (regId) {
                    func({
                        id: [],
                        payload: '',
                        payloadList: [],
                        seriennummer: options.simulateSeriennummer
                    })
                }
            }, options.simulateTimeout);
        }
    }

    async cancelNFC(key: string) {
        const regId = this.nfcRegistrations.get(key);

        if (regId) {
            SystemService.instance.unregisterNfcReceiver(regId);
        }

        if (this.nfcAlert) {
            this.nfcAlert.dismiss();
            this.nfcAlert = null;
        }

        setTimeout(() => BarcodeAuthGuard.barcodeScanAktiv = false, 500);
    }
}
