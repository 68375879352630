import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NavigationStart, ResolveEnd, Router, RouteReuseStrategy } from '@angular/router';
import { IonicStorageModule } from '@ionic/storage';
import { IonicSelectableModule } from 'ionic-selectable';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { RemoteService } from './api/remote.service';
import { AuftragService } from './api/auftrag.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SystemService } from './api/system.service';
import { SharedModule } from './shared/shared.module';
import { Camera } from '@ionic-native/camera/ngx';
import { StammdatenService } from './api/stammdaten.service';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { BarcodeScanner } from "@ionic-native/barcode-scanner/ngx";
import { Network } from '@ionic-native/network/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Push } from '@ionic-native/push/ngx';
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import { NFC, Ndef } from '@ionic-native/nfc/ngx';
import { File } from '@ionic-native/file/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { Printer } from '@ionic-native/printer/ngx';
import { TranslateModule, TranslateLoader, TranslateModuleConfig, MissingTranslationHandler } from '@ngx-translate/core';
import { AppUpdate } from '@ionic-native/app-update/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
// import { WebIntent } from '@ionic-native/web-intent/ngx';
import { DatePicker } from '@ionic-native/date-picker/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { WebIntent } from '@ionic-native/web-intent/ngx';

import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { MyHammerGestureConfig } from '../hammer.config';
import { TourService } from './api/tour.service';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { PowerManagement } from '@ionic-native/power-management/ngx';
import { Dialogs } from '@ionic-native/dialogs/ngx';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
import { Autostart } from '@ionic-native/autostart/ngx';
import { UvvService } from './api/uvv.service';
import { WaageService } from './api/waage.service';
import { BluetoothSerial } from '@ionic-native/bluetooth-serial/ngx';
import { BarcodeAuthGuard } from './shared/barcode.guard';
import { DruckerService } from './api/drucker.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SmartwatchService } from './api/smartwatch.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AppConfig } from './api/helper/app.config';
import { Logger } from './api/helper/app-error-logger';
import { ProgloveService } from './api/proglove.service';
import { GlasAnalyseService } from './api/glassanalyse.service';
import { AppTranslationLoader } from './api/helper/translation-loader';
import { MyMissingTranslationHandler } from './api/helper/missing-translation-handler';
import { ZeiterfassungService } from './api/zeiterfassung.service';
import { BerichteService } from './api/berichte.service';
import { UpdateService } from './api/update.service';
import { AuftragArtikelAuswahlPage } from './auftragsliste/artikel-auswahl/artikel-auswahl.component';
import { AuftragAdressePage } from './auftragsliste/auftrag-adresse/auftrag-adresse.page';
import { AuftragAnmerkungenPage } from './auftragsliste/auftrag-anmerkungen/auftrag-anmerkungen.page';
import { AuftragBezahlenPage } from './auftragsliste/auftrag-bezahlen/auftrag-bezahlen.page';
import { AuftragDruckenPage } from './auftragsliste/auftrag-drucken/auftrag-drucken.page';
import { AuftragLieferungDetailsPage } from './auftragsliste/auftrag-lieferung-details/auftrag-lieferung-details.page';
import { AuftragLieferungPage } from './auftragsliste/auftrag-lieferung/auftrag-lieferung.page';
import { AuftragLkwLadeplanPage } from './auftragsliste/auftrag-lkw-ladeplan/auftrag-lkw-ladeplan.page';
import { AuftragNVEScannenPage } from './auftragsliste/auftrag-nve-scannen/auftrag-nve-scannen.page';
import { AuftragStatusmeldungPage } from './auftragsliste/auftrag-statusmeldung/auftrag-statusmeldung.page';
import { AuftragUnterauftraegeBeladungPage } from './auftragsliste/auftrag-unterauftraege-beladung/auftrag-unterauftraege-beladung.page';
import { AuftragUnterauftraegePage } from './auftragsliste/auftrag-unterauftraege/auftrag-unterauftraege.page';
import { AuftragUnterschriftPage } from './auftragsliste/auftrag-unterschrift/auftrag-unterschrift.page';
import { AuftragWeiterleitenPage } from './auftragsliste/auftrag-weiterleiten/auftrag-weiterleiten.page';
import { AuftragdetailsComponent } from './auftragsliste/auftragdetails/auftragdetails.component';
import { AuftragdetailsBeladungComponent } from './auftragsliste/auftragdetails/beladung/auftragdetails-beladung.component';
import { AuftragdetailsKommissionierungComponent } from './auftragsliste/auftragdetails/kommissionierung/auftragdetails-kommissionierung.component';
import { AuftragAdresseComponent } from './auftragsliste/auftragdetails/komponenten/adresse-komponente.component';
import { AuftragAnmerkungenComponent } from './auftragsliste/auftragdetails/komponenten/anmerkungen-komponente.component';
import { AuftragZeiterfassungKomponenteComponent } from './auftragsliste/auftragdetails/komponenten/auftrag-zeiterfassung-komponente/auftrag-zeiterfassung-komponente.component';
import { AuftragBarcodeComponent } from './auftragsliste/auftragdetails/komponenten/barcode-komponente.component';
import { AuftragBilderComponent } from './auftragsliste/auftragdetails/komponenten/bilder-komponente.component';
import { AuftragBilder2Component } from './auftragsliste/auftragdetails/komponenten/bilder2-komponente.component';
import { AuftragButtonsComponent } from './auftragsliste/auftragdetails/komponenten/buttons-komponente.component';
import { AuftragChecklisteComponent } from './auftragsliste/auftragdetails/komponenten/checkliste-komponente.component';
import { AuftragDateienComponent } from './auftragsliste/auftragdetails/komponenten/dateien-komponente.component';
import { AuftragFahrzeugKennzeichenComponent } from './auftragsliste/auftragdetails/komponenten/fahrzeug-kennzeichen-komponente.component';
import { AuftragHinweistexteComponent } from './auftragsliste/auftragdetails/komponenten/hinweistexte-komponente.component';
import { AuftragLeergutComponent } from './auftragsliste/auftragdetails/komponenten/leergut-komponente.component';
import { AuftragLieferantComponent } from './auftragsliste/auftragdetails/komponenten/lieferant-komponente/lieferant-komponente.component';
import { AuftragLieferungDetailsComponent } from './auftragsliste/auftragdetails/komponenten/lieferung-details-komponente.component';
import { AuftragLieferungComponent } from './auftragsliste/auftragdetails/komponenten/lieferung-komponente.component';
import { AuftragStatusmeldungenComponent } from './auftragsliste/auftragdetails/komponenten/statusmeldungen-komponente.component';
import { AuftragUnterschriftComponent } from './auftragsliste/auftragdetails/komponenten/unterschrift-komponente.component';
import { AuftragMenuPopoverComponent } from './auftragsliste/auftragdetails/popovers/auftrag-menu-popover.component';
import { AuftragslisteMenuPopoverComponent } from './auftragsliste/auftragdetails/popovers/auftragsliste-menu-popover.component';
import { AuftragdetailsStandardComponent } from './auftragsliste/auftragdetails/standard/auftragdetails-standard.component';
import { AuftragdetailsWareneingangComponent } from './auftragsliste/auftragdetails/wareneingang/auftragdetails-wareneingang.component';
import { AuftragdetailsWizardComponent } from './auftragsliste/auftragdetails/wizard/auftragdetails-wizard.component';
import { AuftragslistePage } from './auftragsliste/auftragsliste.page';
import { AuftragspositionWiegenPage } from './auftragsliste/auftragsposition-wiegen/auftragsposition-wiegen.page';
import { KlingelnPage } from './auftragsliste/klingeln/klingeln.page';
import { AuftragRegelInfoComponent } from './auftragsliste/auftragdetails/komponenten/auftrag-regel-info/auftrag-regel-info.component';

const translateOptions: TranslateModuleConfig = {
    loader: {
        provide: TranslateLoader,
        useClass: AppTranslationLoader,
        deps: []
    }
};

translateOptions.missingTranslationHandler = { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler };
translateOptions.useDefaultLang = false;

const animated = localStorage.getItem("Animationen") == 'ja';
// const animated = false; // animationen != 'nein';

// console.log('animationen: ' + animationen);
// console.log('animated: ' + animated);

const log = new Logger('AppModule');

@NgModule({
    declarations: [
        AppComponent,
        AuftragslistePage,
        AuftragdetailsComponent,
        AuftragUnterschriftPage,
        AuftragLieferungPage,
        AuftragAnmerkungenPage,
        AuftragArtikelAuswahlPage,
        AuftragDruckenPage,
        AuftragBezahlenPage,
        AuftragslistePage,
        AuftragAdressePage,
        AuftragspositionWiegenPage,
        AuftragUnterauftraegePage,
        AuftragUnterauftraegeBeladungPage,
        AuftragLkwLadeplanPage,
        AuftragdetailsStandardComponent,
        AuftragdetailsWizardComponent,
        AuftragdetailsKommissionierungComponent,
        AuftragdetailsBeladungComponent,
        AuftragdetailsWareneingangComponent,
        AuftragLieferungDetailsComponent,
        AuftragLieferungDetailsPage,
        AuftragNVEScannenPage,
        AuftragWeiterleitenPage,
        AuftragStatusmeldungPage,
        AuftragMenuPopoverComponent,
        AuftragslisteMenuPopoverComponent,
        AuftragLieferantComponent,
        AuftragRegelInfoComponent,
        AuftragZeiterfassungKomponenteComponent,
        AuftragAdresseComponent,
        AuftragFahrzeugKennzeichenComponent,
        AuftragBarcodeComponent,
        AuftragLieferungComponent,
        AuftragLeergutComponent,
        AuftragHinweistexteComponent,
        AuftragBilderComponent,
        AuftragBilder2Component,
        AuftragAnmerkungenComponent,
        AuftragUnterschriftComponent,
        AuftragStatusmeldungenComponent,
        AuftragChecklisteComponent,
        AuftragDateienComponent,
        AuftragButtonsComponent,
        KlingelnPage,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        TranslateModule.forRoot(translateOptions),
        IonicModule.forRoot({
            mode: 'md',
            animated: animated
        }),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        IonicSelectableModule,
        BrowserAnimationsModule,
        SharedModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }), // environment.production
    ],
    providers: [
        StatusBar,
        SplashScreen,
        BarcodeAuthGuard,
        SystemService,
        DruckerService,
        TourService,
        AuftragService,
        UvvService,
        GlasAnalyseService,
        BerichteService,
        WaageService,
        SmartwatchService,
        ProgloveService,
        RemoteService,
        StammdatenService,
        ZeiterfassungService,
        UpdateService,
        Camera,
        File,
        BluetoothSerial,
        LaunchNavigator,
        BarcodeScanner,
        Network,
        Geolocation,
        NativeAudio,
        Printer,
        BackgroundMode,
        PowerManagement,
        ScreenOrientation,
        AppUpdate,
        Dialogs,
        Push,
        UniqueDeviceID,
        NFC,
        Ndef,
        WebView,
        DocumentViewer,
        FileTransfer,
        FileOpener,
        Autostart,
        AndroidPermissions,
        DatePicker,
        HTTP,
        WebIntent,
        // WebIntent,
        // CameraPreview,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: LOCALE_ID, useValue: 'de-DE' },
        { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerGestureConfig }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor(
        private readonly router: Router,
    ) {
        router.events.subscribe((event) => {
            const logLevel = AppConfig.current?.einstellungen.LogLevelNavigation;

            if (event instanceof NavigationStart && logLevel >= 'A') {
                log.info('ROUTING NavigationStart: ' + event.url);
            } else if (event instanceof ResolveEnd && logLevel >= 'B') {
                log.info('ROUTING ResolveEnd: ' + event.url + ", " + event.urlAfterRedirects);
            }
        })
    }
}
