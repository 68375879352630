import { Storage } from '@ionic/storage';
import { Injectable } from '@angular/core';
import { RemoteService } from './remote.service';
import { SyncResult } from './model/sync-result';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppStorage } from './helper/app-storage';
import { Nachricht, TextdateiTyp, Textdatei, NachrichtAbsenderTyp } from './model/swagger-model';
import { SystemService } from './system.service';
import { AppConfig } from './helper/app.config';
import { Logger } from './helper/app-error-logger';
import { App } from './helper/app';
import { NotificationEventResponse } from '@ionic-native/push/ngx';

import * as moment from 'moment';
import * as pako from 'pako';

import { Utils } from './helper/utils';
import { UiHelper } from './helper/ui-helper';

declare var cordova: any;

const log = new Logger("NachrichtService");

@Injectable({
    providedIn: 'root'
})
export class NachrichtService {
    alleNachrichten: Nachricht[] = null;

    anzahlNeueNachrichten = new BehaviorSubject<number>(0);

    nachrichtenListeGeaendert = new Subject<void>();

    dringendeNachrichtenQueue: Nachricht[] = [];
    dringendeNachrichtWirdAngezeigt = false;

    syncErforderlich = true;
    syncCounter = 0;

    constructor(private storage: Storage,
        private remoteService: RemoteService,
        private systemService: SystemService) {
    }

    async init() {
        // WebSocket-Event
        this.systemService.nachrichtenAktualisieren.subscribe(() => this.syncNachrichtenMitServer('nachrichtenAktualisieren'));

        // Firebase push
        this.systemService.pushNotificationEmpfangen.subscribe(notification => {
            if (notification.additionalData) {
                const typ = notification.additionalData.typ;

                if (typ === "nachricht") {
                    this.verarbeitePushNachricht(notification);
                }
            }
        });

        App.internetAvailable.subscribe(async (p) => {
            if (this.syncErforderlich && AppConfig.current.token) {
                const result = await this.syncNachrichtenMitServer('internetAvailable');

                if (result.success) {
                    this.syncErforderlich = false;
                }
            }
        });

        // Einmal je Stunde nach neuen Nachrichten pollen
        setInterval(async () => {
            if (App.isInternetAvailable() && AppConfig.current.token) {
                const result = await this.syncNachrichtenMitServer('setInterval');

                if (!result.success) {
                    this.syncErforderlich = true;
                }
            } else {
                this.syncErforderlich = true;
            }
        }, 60 * 60 * 1000);

        setTimeout(async () => {
            if (AppConfig.current.token) {
                this.syncNachrichtenMitServer('setTimeout');
            }
        }, 15000);

        const alleNachrichten = await this.getAlleNachrichten();

        this.anzahlNeueNachrichten.next(alleNachrichten.filter(p => !p.Gelesen && p.AbsenderTyp !== NachrichtAbsenderTyp.Geraet).length);

        AppConfig.changed.subscribe(() => {
            this.aktualisiereNachrichtenIcon();
        });

        this.aktualisiereNachrichtenIcon();
    }

    async loescheDaten() {
        await AppStorage.current.set('nachrichten', []);

        this.alleNachrichten = null;
        this.anzahlNeueNachrichten.next(0);
        this.nachrichtenListeGeaendert.next();

        this.aktualisiereNachrichtenIcon();
    }

    aktualisiereNachrichtenIcon() {
        const appConfig = AppConfig.current;
        const app = App.current;

        if (App.current.nachrichtenVerfuegbar.getValue()) {
            switch (appConfig.einstellungen.NachrichtenSymbolInTitelleiste) {
                case 'ja':
                    Utils.next(app.NachrichtenSymbolInTitelleiste, true);
                    break;

                case 'nein':
                    Utils.next(app.NachrichtenSymbolInTitelleiste, false);
                    break;

                case 'vorhanden':
                    Utils.next(app.NachrichtenSymbolInTitelleiste, this.anzahlNeueNachrichten.getValue() > 0);
                    break;

            }
        } else {
            Utils.next(app.NachrichtenSymbolInTitelleiste, false);
        }
    }

    async sendeNachricht(text: string): Promise<void> {
        const fahrer = App.current.fahrer.getValue();
        const fahrzeug = App.current.fahrzeug.getValue();

        let absender = '';

        if (fahrer) {
            absender = fahrer.vorname + ' ' + fahrer.nachname;
        }

        if (fahrzeug) {
            absender += ' / ' + fahrzeug.kennzeichen;
        }

        const nachricht: Nachricht = {
            Timestamp: Date.now(),
            Guid: Utils.uuid(),
            AbsenderTyp: NachrichtAbsenderTyp.Geraet,
            Absender: absender,
            Datum: moment().toISOString(),
            Gelesen: false,
            // Gesendet: true,
            // Status: 'neu',
            // GesendetDatum: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
            Text: text,
            Dringend: false,
        };

        await this.speichereNachricht(nachricht);

        const textdatei: Textdatei = {
            typ: TextdateiTyp.Nachricht,
            datum: moment().toISOString(),
            geraeteNummer: AppConfig.current.geraeteNummer,
            nachricht
        };

        const mitGpsPosition = AppConfig.current.einstellungen.GpsPositionBeiNachricht === 'ja';

        // Kein await hier aus Performance-Gründen
        this.systemService.sendeTextdatei(textdatei, mitGpsPosition);

        this.nachrichtenListeGeaendert.next();

        // await this.syncNachrichtenMitServer();
    }

    async syncNachrichtenMitServer(info: string, loading: HTMLIonLoadingElement = null): Promise<SyncResult> {
        try {
            log.debug('syncNachrichtenMitServer: ' + info);

            if (!AppConfig.current.token) {
                log.debug('syncNachrichtenMitServer: abbruch, kein Token');

                return {
                    success: false
                };
            }

            // Lade alle lokalen Nachrichten
            const nachrichten = await this.getAlleNachrichten();

            let lastTimestamp = await AppStorage.current.get('nachrichten-last-timestamp', true);

            if (!lastTimestamp) {
                lastTimestamp = 0;
            }

            let neueNachrichten = await this.remoteService.getNeueNachrichten(lastTimestamp);

            if (!neueNachrichten) {
                return {
                    errorMessage: 'Nachrichten konnten nicht vom Server geladen werden. Bitte Internetverbindung kontrollieren.',
                    success: false
                };
            }

            if (neueNachrichten.length) {
                neueNachrichten = neueNachrichten.sort((a, b) => a.Timestamp - b.Timestamp);

                const dringendeNachrichten: Nachricht[] = [];
                let neueNachrichtGefunden = false;

                for (const nachricht of neueNachrichten) {
                    if (!nachrichten.find(p => p.Guid === nachricht.Guid)) {
                        nachrichten.push(nachricht);
                        neueNachrichtGefunden = true;

                        if (nachricht.Timestamp > lastTimestamp) {
                            lastTimestamp = nachricht.Timestamp;
                        }

                        if (nachricht.Dringend) {
                            dringendeNachrichten.push(nachricht);
                        }
                    }
                }

                if (neueNachrichtGefunden) {
                    this.speichereNachrichten(nachrichten);

                    await AppStorage.current.set('nachrichten-last-timestamp', lastTimestamp);

                    this.nachrichtenListeGeaendert.next();

                    if (App.current.NachrichtenSoundAbspielen.getValue()) {
                        this.systemService.beepFuerNeueNachricht();
                    }

                    if (dringendeNachrichten.length) {
                        if (loading) {
                            await loading.dismiss();
                        }

                        this.anzeigenDringendeNachrichten(dringendeNachrichten);
                    }
                }
            }

            return {
                success: true,
                newItems: 0
            };
        } catch (err) {
            log.warn('syncNachrichtenMitServer: ' + Utils.getErrorMessage(err), err);

            return {
                errorMessage: err,
                success: false
            };
        }
    }

    anzeigenDringendeNachrichten(neueDringendeNachrichten: Nachricht[]) {
        for (const nachricht of neueDringendeNachrichten) {
            if (this.dringendeNachrichtenQueue.find(p => p.Guid == nachricht)) {
                log.debug('Dringende Nachricht ist bereits in Queue vorhanden. Nicht anzeigen: ' + JSON.stringify(nachricht));
                continue;
            }

            this.dringendeNachrichtenQueue.push(nachricht);
        }

        try {
            this.dringendeNachrichtenQueue = this.dringendeNachrichtenQueue.sort((a, b) => a.Timestamp - b.Timestamp);
        } catch (err) {
            log.error(Utils.getErrorMessage(err))
        }

        this.anzeigenNaechsteDringendeNachricht();
    }

    async anzeigenNaechsteDringendeNachricht() {
        if (!this.dringendeNachrichtenQueue.length) {
            return;
        }

        if (this.dringendeNachrichtWirdAngezeigt) {
            log.debug('Dringende Nachricht wird bereits angezeigt. Warte bis Alert-Fenster geschlossen wurde.');
            return;
        }

        this.dringendeNachrichtWirdAngezeigt = true;

        try {
            // Hole die erste Nachricht
            const nachricht = this.dringendeNachrichtenQueue[0];

            log.debug('Anzeigen nächste dringende Nachricht: ' + nachricht?.Text);

            // Entferne den ersten Eintrag aus der Liste
            this.dringendeNachrichtenQueue.splice(0, 1);

            if (!nachricht.Gelesen) {
                if (!this.systemService.isVisible.getValue()) {
                    if (App.isCordovaAvailable()) {
                        // const localNotifications = (cordova.plugins as any).notification?.local;

                        // localNotifications.schedule({
                        //     title: 'Neue Nachricht',
                        //     text: nachricht.Text,
                        //     vibrate: true,
                        //     wakeup: true,
                        //     badge: 0,
                        //     priority: 2,
                        //     lockscreen: true,
                        //     foreground: true,
                        //     launch: true,
                        //     channelName: 'Nachrichten',
                        //     sound: 'res://platform_default',
                        // });

                        // Anwendung in den Vordergrund bringen
                        this.systemService.moveToForeground('neue dringende Nachricht');
                    }
                }

                await UiHelper.showAlert(nachricht.Text, 'Neue Nachricht', true);

                const now = moment().toISOString();

                const textdatei: Textdatei = {
                    typ: TextdateiTyp.NachrichtGelesen,
                    datum: now,
                    geraeteNummer: AppConfig.current.geraeteNummer,
                    Nachrichten: [{
                        Guid: nachricht.Guid,
                        Gelesen: true,
                        GelesenDatum: moment().toISOString(),
                        GelesenVon: App.current.getPersonalKey()
                    }]
                };

                await this.systemService.sendeTextdatei(textdatei, false);
            }
        } catch (err) {
            log.error(Utils.getErrorMessage(err));
        } finally {
            this.dringendeNachrichtWirdAngezeigt = false;
        }

        // Nächste Nachricht anzeigen
        this.anzeigenNaechsteDringendeNachricht();
    }

    async getAlleNachrichten(): Promise<Nachricht[]> {
        if (this.alleNachrichten) {
            return this.alleNachrichten;
        }

        let nachrichten: Nachricht[] = await AppStorage.current.get('nachrichten', false);

        if (!nachrichten) {
            nachrichten = [];
        }

        for (const nachricht of nachrichten) {
            this.fixNachricht(nachricht);
        }

        // Lösche zu alte Nachrichten
        const maxAlterTage = App.current.NachrichtenMaxAlter.getValue();

        if (maxAlterTage > 0) {
            const zuAltDatum = moment().subtract(maxAlterTage, 'days');

            if (nachrichten.length > 0) {
                nachrichten = nachrichten.filter(p => {
                    if (!p.Datum) {
                        return false;
                    }

                    try {
                        const datum = moment(p.Datum);

                        if (datum > zuAltDatum) {
                            return true;
                        }
                    } catch (err) {
                        log.error(err);
                    }

                    return false;
                });
            }
        }

        // nachrichten = nachrichten.sort((a, b) => {
        //     const d1 = moment(a.Datum);
        //     const d2 = moment(b.Datum);

        //     if (d1 < d2) {
        //         return -1;
        //     } else if (d2 > d1) {
        //         return 1;
        //     }

        //     return a.Id - b.Id;
        // });

        return nachrichten;
    }

    async speichereNachricht(nachricht: Nachricht): Promise<void> {
        const nachrichten = await this.getAlleNachrichten();

        const index = nachricht.Guid
            ? nachrichten.findIndex(p => p.Guid === nachricht.Guid)
            : -1;

        if (index >= 0) {
            nachrichten[index] = nachricht;
        } else {
            nachrichten.push(nachricht);
        }

        await this.speichereNachrichten(nachrichten);
    }

    async speichereNachrichten(nachrichten): Promise<void> {
        log.debug('speichereNachrichten');

        this.alleNachrichten = nachrichten;
        await AppStorage.current.set('nachrichten', nachrichten);

        const neueNachrichten = this.alleNachrichten.filter(p => !p.Gelesen && p.AbsenderTyp !== NachrichtAbsenderTyp.Geraet);
        this.anzahlNeueNachrichten.next(neueNachrichten.length);

        this.aktualisiereNachrichtenIcon();
    }

    private fixNachricht(nachricht: Nachricht) {
        // TODO
    }

    // private async getMaxId() {
    //     let maxId = 0;

    //     const nachrichten = await this.getAlleNachrichten();

    //     if (nachrichten.length) {
    //         maxId = nachrichten.map(p => p.Id).reduce((prev, next) => next > prev ? next : prev);
    //     }

    //     return maxId;
    // }

    /**
     * Wird aufgerufen wenn eine neue Nachricht per Firebase-Push empfangen wurde
     */
    async verarbeitePushNachricht(notification: NotificationEventResponse) {
        log.info('verarbeitePushNachricht', notification);

        const myCounter = ++this.syncCounter;

        let text = '';
        let nachricht: Nachricht = null;

        try {
            const nachrichten = await this.getAlleNachrichten();

            const docodedData = atob(notification.additionalData.text);
            text = pako.inflate(docodedData, { to: 'string' });

            nachricht = {
                Guid: notification.additionalData.key,
                Dringend: notification.additionalData.dringend == "1",
                Text: text,
                Absender: notification.additionalData.absender,
                AbsenderTyp: NachrichtAbsenderTyp.Extern,
                Datum: notification.additionalData.datum,
                Timestamp: notification.additionalData.timestamp,
                Gelesen: false,
            }

            if (nachrichten.find(p => p.Guid === nachricht.Guid)) {
                log.debug('Nachricht ist schon vorhanden. Ignorieren')
                return;
            }

            if (!this.alleNachrichten) {
                this.alleNachrichten = [];
            }

            this.alleNachrichten.push(nachricht);

            this.speichereNachrichten(nachrichten);

            // await AppStorage.current.set('nachrichten-last-timestamp', lastTimestamp);

            this.nachrichtenListeGeaendert.next();

            if (App.current.NachrichtenSoundAbspielen.getValue()) {
                this.systemService.beepFuerNeueNachricht();
            }
        } catch (err) {
            log.error('verarbeitePushNachricht: ' + Utils.getErrorMessage(err));

            this.syncErforderlich = true;
        }

        if (this.syncErforderlich && this.systemService.isInternetAvailable()) {
            log.debug('syncErforderlich und InternetAvailable')

            setTimeout(async () => {
                if (this.syncErforderlich && myCounter === this.syncCounter) {
                    const result = await this.syncNachrichtenMitServer('setTimeout 2');

                    if (result.success) {
                        this.syncErforderlich = false;
                    }
                }
            }, 2500);
        }

        if (nachricht && nachricht.Dringend) {
            this.anzeigenDringendeNachrichten([nachricht]);
        }

        // const dringend = notification.additionalData.dringend == "1";

        // if (dringend && text) {

        //     await UiHelper.showAlert(text, 'Neue Nachricht', true);

        //     const now = moment().toISOString();

        //     const textdatei: Textdatei = {
        //         typ: TextdateiTyp.NachrichtGelesen,
        //         datum: now,
        //         geraeteNummer: AppConfig.current.geraeteNummer,
        //         Nachrichten: [{
        //             Guid: notification.additionalData.key,
        //             Gelesen: true,
        //             GelesenDatum: moment().toISOString(),
        //             GelesenVon: App.current.getPersonalKey()
        //         }]
        //     };

        //     await this.systemService.sendeTextdatei(textdatei, false);
        // }

        // const nachrichtId = notification.additionalData.key;

        // const foreground: boolean = notification.additionalData.foreground;

        // // When the app is not running and the user taps the notification, the app get TWO notifications
        // // (as described above). But the first is with coldstart=false, second with coldstart=true.
        // // https://github.com/phonegap/phonegap-plugin-push/issues/2549
        // const coldstart: boolean = notification.additionalData.foreground;

        // // TODO: Aktion auswerten. neu|aktualisiert|geloescht|archiviert... ?
        // // const aktion = notification.additionalData.aktion;

        // const auftragDto = await this.remoteService.getAuftragById(auftragId);

        // this.log.info('auftragDto', auftragDto);

        // if (auftragDto) {
        //     const auftrag = this.entpackeAuftragsdaten(auftragDto);

        //     this.log.info('entpackter auftrag', auftrag);

        //     if (auftrag) {
        //         this.letzterEmpfangenerAuftrag = auftrag;

        //         await this.pruefeAktiverAuftrag(auftrag);

        //         // Kein await. Ist beim Start viel zu langsam
        //         this.speichereAuftrag(auftrag);

        //         setTimeout(() => {
        //             if (this.letzterEmpfangenerAuftrag.Key == auftrag.Key) {
        //                 // Klingeln, bei Bedarf. Keine Notification, da ja schon eine Notification empfangen wurde
        //                 this.verarbeiteNeueAuftraege([auftrag], false);
        //             } else {
        //                 this.log.debug('Es wurde in der Zwischenzeit ein weiterer Auftrag empfangen. Nicht klingeln.');
        //             }
        //         }, 1000);
        //     }
        // }
    }
}
